@import 'bootstrap/_bootstrap.scss';
$mainColor:#EC6053;
// sass mix
@mixin img2x($file, $type, $width, $height) {
    background-image: url($file + '.' + $type);
    background-repeat: no-repeat;
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        & {
            background-image: url($file + '@2x.' + $type);
            -webkit-background-size: $width $height;
            -moz-background-size: $width $height;
            -o-background-size: $width $height;
            background-size: $width $height;
            background-repeat: no-repeat;
        }
    }
}
body {
    font-family: 'Helvetica Neue', Helvetica, Arial, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', sans-serif;
    background-color: #F8F8F8;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeSpeed;
}
a {
    &:hover, &:focus, &:active {
        text-decoration: none;
    }
}
ul, li, ol {
    list-style: none;
    padding: 0;
    margin: 0;
}
.mt10 {
    margin-top: 10px;
}
.mt20 {
    margin-top: 20px;
}
.mb10 {
    margin-bottom: 10px;
}
.mb20 {
    margin-bottom: 20px;
}
.header {
    height: 60px;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.10);
    border-bottom: 1px solid #eee;
    .logo {
        display: block;
        float: left;
        height: 60px;
        line-height: 60px;
        img {
            height: 36px;
        }
    }
    .user-dropdown {
        .dropdown-label {
            padding-left: 10px;
            padding-right: 10px;
        }
        .user-img {
            width: 30px;
            height: 30px;
            margin-right: 10px;
            display: inline-block;
            *display: inline;
            *zoom: 1;
            img {
                width: 100%;
            }
        }
        .dropdown-menu {
            min-width: 180px;
            padding: 0;
            margin-top: -1px;
            border-radius: 0;
            border: 0;
            li {
                border-top: 1px solid #eee;
                a {
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
            }
        }
    }
    .header-menu {
        float: right;
        border-left: 1px solid #EFEFEF;
        margin: 0;
        padding: 0;
        &>li {
            float: left;
            list-style: none;
            line-height: 60px;
            border-right: 1px solid #EFEFEF;
            a {
                display: block;
                padding: 0 20px;
                color: #484848;
                font-size: 15px;
                &:hover {
                    opacity: .8;
                    background-color: #f9f9f9;
                }
                svg {
                    display: inline-block;
                    *display: inline;
                    *zoom: 1;
                    vertical-align: middle;
                    margin-right: 10px;
                }
            }
        }
    }
}
.index-hero {
    height: 500px;
    margin-bottom: 125px;
    position: relative;
    .hero-bg {
        background-size: cover;
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
        height: 500px;
        overflow: hidden;
        .bg-item {
            background-size: cover;
            height: 500px;
            background-repeat: no-repeat;
            background-position: center;
        }
        .slick-arrow {
            width: 40px;
            height: 40px;
            background-color: rgba(#000, .1);
            border: 0;
            border-radius: 50%;
            top: 50%;
            margin-top: -30px;
            position: absolute;
            z-index: 99;
            text-indent: -9999px;
            background-position: center;
            background-size: auto 15px;
            background-repeat: no-repeat;
            outline: 0;
            transition: .3s;
            &:hover {
                background-color: rgba(#000, .3);
            }
        }
        .slick-prev {
            background-image: url(../images/left-arrow.png);
            left: 3%;
        }
        .slick-next {
            background-image: url(../images/right-arrow.png);
            right: 3%;
        }
    }
    .hero-content {
        position: relative;
        color: #fff;
        text-align: center;
        padding-top: 125px;
        h2 {
            font-size: 38px;
            font-weight: bold;
            margin: 0 0 10px;
            padding: 0;
        }
        p {
            font-size: 18px;
        }
    }
    .hero-form {
        max-width: 600px;
        margin: 40px auto 0;
        position: relative;
        input {
            width: 100%;
            height: 50px;
            border-radius: 5px;
            border: 0;
            padding-left: 20px;
            padding-right: 120px;
            font-size: 16px;
            color: #333;
            outline: 0;
            transition: .3s;
            &:focus {
                box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.1);
            }
        }
        button {
            position: absolute;
            width: 100px;
            height: 50px;
            right: 0;
            top: 0;
            border: 0;
            outline: 0;
            background-color: #E73828;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            @include img2x('../images/icon-search', 'png', 20px, 20px);
            background-position: center;
        }
    }
    .hero-menu {
        position: absolute;
        left: 50%;
        bottom: -125px;
        width: 810px;
        margin-left: -405px;
        background: #FFFFFF;
        box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.10);
        border-radius: 5px;
        padding: 0;
        overflow: hidden;
        @include clearfix;
        li {
            padding: 0;
            list-style: none;
            width: 33.33%;
            float: left;
            text-align: center;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                width: 1px;
                height: 40%;
                background-color: #eee;
                right: 0;
                top: 30%;
            }
            &:last-child {
                &:after {
                    display: none;
                }
            }
            svg {
                display: block;
                width: 60px;
                margin: 0 auto 30px;
            }
            a {
                color: #333;
                display: block;
                padding: 50px 0;
                transition: .3s;
                text-decoration: none;
                outline: 0;
                p {
                    font-size: 18px;
                }
                &:hover {
                    background-color: #fafafa;
                }
            }
        }
    }
}
.index-hot {
    padding-bottom: 65px;
    background-color: #f8f8f8;
    &.hot-apartment {
        margin-top: -60px;
        background-color: #fff;
    }
    h2 {
        font-size: 30px;
        color: #4C4C4C;
        font-weight: normal;
        text-align: center;
        margin: 60px 0;
        span {
            color: #8B8B8B;
            display: block;
            font-size: 14px;
            margin-top: 10px;
        }
    }
    .sub-intro {
        font-size: 16px;
        margin-top: -30px;
        margin-bottom: 50px;
        text-align: center;
    }
    .hot-list {
        max-width: 1000px;
        margin: 0 auto;
        @include clearfix;
        li {
            width: 33.33%;
            float: left;
            padding-left: 10px;
            padding-right: 10px;
            margin-bottom: 20px;
            &:hover {
                .hot-img {
                    z-index: 2;
                    box-shadow: 0 15px 50px rgba(0, 0, 0, 0.1);
                    transform: translateY(-5px);
                }
            }
        }
        .hot-img {
            transition: .3s;
            transform: translateY(0);
            height: 210px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            img {
                width: 100%;
            }
        }
        .hot-title {
            color: #4C4C4C;
            margin-top: 10px;
            .hot-local {
                color: #999;
                float: right;
            }
        }
    }
    .hot-more {
        font-size: 16px;
        color: #8b8b8b;
        margin: 30px auto;
        display: inline-block;
        *display: inline;
        *zoom: 1;
        line-height: 32px;
        border-bottom: 1px solid #bbb;
        &:hover {
            color: $mainColor;
            border-bottom-color: $mainColor;
        }
    }
}
.index-intro {
    background-color: #fbfbfb;
    padding: 80px 0;
    text-align: center;
    hr {
        margin: 80px 0;
        border: 0;
        height: 1px;
        background-color: #EEEEEE;
    }
    h2 {
        font-size: 30px;
        color: #4C4C4C;
        font-weight: normal;
        text-align: center;
        padding: 0;
        margin: 0 0 30px;
    }
    p {
        font-size: 16px;
    }
    .intro-list {
        padding: 30px 0;
        h3 {
            font-size: 18px;
            font-weight: normal;
        }
        p {
            font-size: 14px;
            color: #8B8B8B;
            line-height: 25px;
        }
        i {
            display: block;
            width: 90px;
            height: 90px;
            margin: 0 auto 20px;
            background-position: center;
            transform: translateY(0);
            transition: .3s;
            &.icon-intro01 {
                @include img2x('../images/icon-intro01', 'png', auto, 85px);
            }
            &.icon-intro02 {
                @include img2x('../images/icon-intro02', 'png', auto, 85px);
            }
            &.icon-intro03 {
                @include img2x('../images/icon-intro03', 'png', auto, 85px);
            }
            &.icon-intro04 {
                @include img2x('../images/icon-intro04', 'png', auto, 85px);
            }
        }
        li {
            &:hover {
                i {
                    transform: translateY(-5px);
                }
            }
        }
    }
}
#indexSearch {
    .twitter-typeahead {
        width: 100%;
    }
    .typeahead {
        background-color: #fff;
    }
    .tt-query {
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    }
    .tt-hint {
        color: #999;
    }
    .tt-menu {
        width: 100%;
        margin: 0 0;
        text-align: left;
        color: #333;
        padding: 0;
        background-color: #fff;
        border-radius: 6px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, .2);
        h3 {
            padding-left: 20px;
            padding-right: 20px;
            font-size: 14px;
            color: #999;
            line-height: 24px;
            margin-top: 10px;
        }
    }
    .tt-suggestion {
        padding: 3px 20px;
        font-size: 14px;
        line-height: 24px;
        &:hover {
            cursor: pointer;
            background-color: #f0f0f0;
        }
        &.tt-cursor {
            background-color: #f0f0f0;
        }
        p {
            margin: 0;
        }
    }
    .tt-dataset {
        margin: 10px 0;
        padding-bottom: 10px;
        border-bottom: 1px solid #eee;
        &:last-child {
            padding: 0;
            border-bottom: 0;
        }
    }
    .league-name {
        span {
            line-height: 16px;
            display: inline-block;
            *display: inline;
            *zoom: 1;
            vertical-align: middle;
        }
        i {
            display: inline-block;
            *display: inline;
            *zoom: 1;
            margin-right: 8px;
            width: 16px;
            height: 16px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            vertical-align: middle;
        }
        .icon-city {
            background-image: url(../images/icon-city.png);
        }
        .icon-school {
            background-image: url(../images/icon-school.png);
        }
    }
}
.footer {
    background-color: #484848;
    padding: 40px 0 80px;
    h3 {
        font-size: 16px;
        color: #fff;
        opacity: .8;
        margin-bottom: 24px;
    }
    .qrcode {
        width: 100px;
        height: 100px;
    }
    .col-xs-6 {
        height: 230px;
    }
    .footer-menu {
        font-size: 14px;
        line-height: 32px;
        color: #A3A3A3;
        a {
            color: #A3A3A3;
            transition: .3s;
            &:hover {
                opacity: .8;
            }
        }
    }
}
.footer-logo {
    img {
        height: 80px;
    }
}
.header-warp {
    position: relative;
    padding-top: 60px;
    &.header-fixed {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
    }
    .header {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
    }
    .sub-header {
        background-color: #fff;
        height: 50px;
        .sub-menu {
            li {
                display: inline-block;
                *display: inline;
                *zoom: 1;
                vertical-align: top;
                a {
                    display: block;
                    height: 50px;
                    line-height: 50px;
                    padding: 0 20px;
                    color: #666666;
                    &:hover {
                        opacity: .8;
                    }
                }
                &.active {
                    a {
                        color: $mainColor;
                    }
                }
            }
        }
    }
}
.search-filter {
    float: left;
    .filter-item {
        line-height: 50px;
        display: inline-block;
        *display: inline;
        *zoom: 1;
        height: 50px;
        color: #666;
        margin: 0 20px;
        cursor: pointer;
        position: relative;
        i.icon-down {
            display: inline-block;
            *display: inline;
            *zoom: 1;
            vertical-align: middle;
            margin-left: 5px;
            width: 16px;
            height: 8px;
            @include img2x('../images/icon-down', 'png', 16px, 8px);
        }
        &.active {
            .item-dropdown {
                display: block;
            }
        }
    }
    .item-dropdown {
        position: absolute;
        z-index: 999;
        background-color: #fff;
        width: 350px;
        border-radius: 3px;
        border: 1px solid #eee;
        padding: 20px;
        line-height: 1;
        left: 0;
        display: none;
        .bd {
            margin-bottom: 10px;
        }
        .ft {
            @include clearfix;
            .cancel-link {
                float: left;
                line-height: 1.5;
                color: #666;
            }
            .apply-link {
                float: right;
                line-height: 1.5;
                color: $mainColor;
            }
        }
    }
}
.checkbox-warp {
    margin-bottom: 10px;
    .checkbox-cell {
        display: inline-block;
        *display: inline;
        *zoom: 1;
        margin-right: 10px;
        input {
            display: none;
            &:checked+label {
                border-color: $mainColor;
                background-color: $mainColor;
                color: #fff;
            }
        }
        label {
            display: inline-block;
            *display: inline;
            *zoom: 1;
            cursor: pointer;
            padding: 10px;
            border: 1px solid #d9d9d9;
            border-radius: 2px;
            font-weight: normal;
        }
    }
}
#priceInterval {
    margin: 10px 10px 50px;
}
.irs-bar {
    background: $mainColor;
    border: none;
}
.irs-min, .irs-max {
    display: none;
}
.irs-from, .irs-single, .irs-to {
    background-color: #eee;
    color: #333;
}
.irs-with-grid .irs-grid {
    display: none;
}
.search-tools {
    float: right;
    font-size: 0;
    .serach-form {
        width: 403px;
        display: inline-block;
        *display: inline;
        *zoom: 1;
        vertical-align: top;
        font-size: 16px;
        border-left: 1px solid #EFEFEF;
        height: 50px;
        input {
            border: 0;
            height: 50px;
            padding: 10px 10px 10px 50px;
            width: 100%;
            outline: 0;
            background: url('../images/search-icon.png') no-repeat 15px center;
            &::-webkit-input-placeholder {
                color: #d9d9d9;
            }
        }
    }
    .search-toogle {
        display: inline-block;
        *display: inline;
        *zoom: 1;
        width: 102px;
        text-align: center;
        vertical-align: top;
        font-size: 14px;
        height: 50px;
        line-height: 50px;
        color: #484848;
        border-left: 1px solid #EFEFEF;
        border-right: 1px solid #EFEFEF;
        &:hover {
            opacity: .8;
            background-color: #f9f9f9;
        }
    }
    .icon-map {
        display: inline-block;
        *display: inline;
        *zoom: 1;
        vertical-align: middle;
        width: 14px;
        height: 20px;
        background: url(../images/icon-map@2x.png) no-repeat center;
        background-size: cover;
        margin-right: 3px;
    }
    .icon-list {
        display: inline-block;
        *display: inline;
        *zoom: 1;
        vertical-align: middle;
        width: 16px;
        height: 14px;
        background: url(../images/icon-list@2x.png) no-repeat center;
        background-size: cover;
        margin-right: 3px;
    }
}
.sort-box {
    margin: 20px 0;
    background-color: #fff;
    position: relative;
    span, li, ul {
        display: inline-block;
        *display: inline;
        *zoom: 1;
        vertical-align: middle;
    }
    .title {
        padding: 12px 15px;
    }
    .sort-list {
        li {
            border-right: 1px solid #EFEFEF;
            border-left: 1px solid #efefef;
            margin-left: -1px;
            float: left;
            padding: 12px 15px;
            position: relative;
            &:hover {
                background-color: #FBEFEE;
            }
            &.active {
                color: $mainColor;
                background-color: #FBEFEE;
                a {
                    color: $mainColor;
                }
            }
            .icon-triangle {
                display: inline-block;
                margin-left: .5rem;
                border-top: 6px solid #4c4c4c;
                border-right: 4px solid transparent;
                border-left: 4px solid transparent;
                vertical-align: middle;
            }
            .icon-trend {
                width: 14px;
                height: 14px;
                display: inline-block;
                *display: inline;
                *zoom: 1;
                vertical-align: middle;
                background-position: center;
                margin-left: 1px;
                @include img2x('../images/icon-trend', 'png', 10px, 12px);
                &.up {
                    @include img2x('../images/icon-trend-up', 'png', 10px, 12px);
                }
            }
            .sort-list-menu {
                position: absolute;
                right: 0;
                left: auto;
                top: 100%;
                box-shadow: 0 2px 16px 0 rgba(76, 76, 76, .08);
                z-index: 999;
                display: none;
                width: 100%;
                border-top: 1px solid #eee;
                li {
                    display: block;
                    width: 100%;
                    float: none;
                    background-color: #fff;
                    color: #4c4c4c;
                    text-align: center;
                    cursor: pointer;
                    padding: 0;
                    a {
                        color: $mainColor;
                        padding: 10px;
                        display: block;
                        &:hover {
                            background-color: darken(#FBEFEE, 2);
                        }
                    }
                    &.active {
                        background-color: #FBEFEE;
                    }
                }
            }
            &:hover {
                .sort-list-menu {
                    display: block;
                }
            }
        }
    }
}
.search-item {
    background-color: #fff;
    margin-bottom: 20px;
    .item-img {
        display: block;
        height: 210px;
        background-position: center;
        background-size: cover;
        position: relative;
        overflow: hidden;
        .sold-out {
            position: absolute;
            right: 15px;
            top: 15px;
            font-size: 13px;
            color: #fff;
            border: 1px solid #EC6053;
            border-radius: 100px;
            padding: 0 8px;
            line-height: 20px;
            z-index: 2;
            background-color: #EC6053;
        }
        .item-bg {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: #f9f9f9;
            z-index: 1;
            transform: scale(1);
            transition: 1s;
        }
        &:after {
            position: absolute;
            content: '';
            background-color: rgba(#4C4C4C, 0.05);
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 5;
        }
        .item-price {
            position: absolute;
            z-index: 9;
            left: 0;
            bottom: 10px;
            background-color: rgba(#4C4C4C, 0.7);
            color: #fff;
            font-size: 20px;
            padding: 5px 12px;
        }
    }
    &:hover {
        .item-img {
            .item-bg {
                transform: scale(1.4);
            }
        }
    }
    .item-intro {
        padding: 15px;
        position: relative;
        .goto {
            position: absolute;
            width: 40px;
            height: 40px;
            right: 15px;
            top: -20px;
            border-radius: 50%;
            background: #FFFFFF;
            @include img2x('../images/icon-local', 'png', 15px, 19px);
            background-position: center;
            box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
            z-index: 99;
        }
    }
    .item-title {
        margin-top: 0;
        margin-bottom: 10px;
        padding-right: 50px;
        a {
            font-size: 18px;
            color: #484848;
            @include text-overflow;
            display: block;
            &:hover {
                color: $mainColor;
            }
        }
    }
    .item-local {
        color: #8B8B8B;
        margin-bottom: 10px;
    }
    .disable-collect {
        position: absolute;
        z-index: 99;
        right: 20px;
        top: 15px;
        font-size: 12px;
        background-color: rgba(#000, .5);
        border: 1px solid #aaa;
        padding: 5px;
        margin-top: -5px;
        line-height: 1;
        border-radius: 3px;
        border-radius: 3px;
        color: #fff;
    }
    .item-tools {
        position: relative;
        color: #8B8B8B;
        .item-cat {
            position: absolute;
            right: 0;
            top: 0;
        }
    }
    .item-tags {
        a {
            display: inline-block;
            *display: inline;
            *zoom: 1;
            height: 20px;
            line-height: 20px;
            margin-right: 6px;
            font-size: 13px;
            color: #484848;
            background: #F8F8F8;
            border: 1px solid #DFE1E4;
            border-radius: 100px;
            padding: 0 10px;
        }
    }
}
.pagination>li:first-child>a, .pagination>li:first-child>span, .pagination>li:last-child>a, .pagination>li:last-child>span {
    border-radius: 0;
    border: 1px solid #ddd;
    margin: 0 5px;
}
.pagination {
    &>li a {
        margin: 0;
        color: #8B8B8B;
        background-color: #F8F8F8;
        border: 0;
        &:hover {
            color: $mainColor;
            background-color: #F8F8F8;
        }
    }
}
.pagination>.active>a, .pagination>.active>a:hover, .pagination>.active>a:focus, .pagination>.active>span, .pagination>.active>span:hover, .pagination>.active>span:focus {
    background-color: transparent;
    color: $mainColor;
}
.search-histroy {
    margin-bottom: 50px;
    h3 {
        font-size: 18px;
        margin-bottom: 10px;
        margin-top: 0;
    }
}
.histroy-item {
    text-align: center;
    .item-img {
        height: 160px;
        background-position: center;
        background-size: cover;
    }
    h4 {
        font-size: 16px;
        color: #484848;
    }
    a:hover {
        h4 {
            color: $mainColor;
        }
    }
    p {
        color: #8B8B8B;
    }
}
.search-map-warp {
    width: 100%;
    height: 100%;
    height: 100vh;
    padding-top: 110px;
    .search-map {
        width: 100%;
        height: 100%;
        position: relative;
        #map {
            width: 100%;
            height: 100%;
        }
    }
}
.map-marker {
    position: absolute;
    z-index: 10;
    .marker-label {
        background-color: #EC6053;
        color: #fff;
        font-size: 14px;
        display: inline-block;
        *display: inline;
        *zoom: 1;
        padding: 4px 10px;
        border-radius: 3px;
        cursor: pointer;
        position: relative;
        border: 1px solid $mainColor;
        &:after {
            position: absolute;
            content: '';
            width: 0;
            height: 0;
            left: 50%;
            bottom: -6px;
            margin-left: -6px;
            border-style: solid;
            border-width: 6px 6px 0 6px;
            border-color: #ec6053 transparent transparent transparent;
        }
    }
    .map-intro {
        position: absolute;
        background: #FFFFFF;
        box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.25);
        border-radius: 3px;
        width: 220px;
        padding: 8px;
        left: 50%;
        margin-left: -110px;
        bottom: 48px;
        display: none;
        z-index: 999;
        &:after {
            position: absolute;
            content: '';
            left: 50%;
            margin-left: -14px;
            bottom: -14px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 14px 14px 0 14px;
            border-color: #ffffff transparent transparent transparent;
        }
    }
    &.active {
        .map-intro {
            display: block;
        }
        .marker-label {
            background-color: #fff;
            color: $mainColor;
        }
    }
    .item-img {
        position: relative;
        .sold-out {
            position: absolute;
            right: 5px;
            top: 5px;
            font-size: 12px;
            color: #fff;
            border: 1px solid #EC6053;
            border-radius: 100px;
            padding: 0 8px;
            line-height: 20px;
            z-index: 2;
            background-color: #EC6053;
        }
    }
    .item-bg {
        height: 150px;
        background-size: cover;
    }
    .item-price {
        position: absolute;
        bottom: 10px;
        background-color: #4c4c4c;
        background-color: rgba(#4c4c4c, 0.7);
        color: #fff;
        font-size: 16px;
        padding: 5px 10px;
    }
    .item-title {
        font-size: 14px;
        margin-top: 10px;
        margin-bottom: 5px;
        color: #4c4c4c;
    }
    &:hover {
        .item-title {
            color: $mainColor;
        }
    }
    .item-local {
        color: #8B8B8B;
        font-size: 12px;
    }
}
.uc-warp {
    padding-top: 20px;
    padding-bottom: 80px;
    .sort-box {
        margin-top: 0;
    }
    h2.cell-title {
        font-size: 22px;
        font-weight: normal;
        margin-bottom: 15px;
        margin-top: 10px;
    }
}
.uc-box {
    padding: 30px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    border-radius: 3px;
    margin-bottom: 20px;
}
.uc-form {
    h3 {
        margin-top: -10px;
        margin-bottom: 20px;
        font-size: 20px;
        border-bottom: 1px solid #eee;
        padding-bottom: 15px;
    }
    .form-horizontal .control-label {
        font-size: 16px;
        text-align: left;
        color: #484848;
        font-weight: normal;
        padding-top: 10px;
    }
    .form-group {
        margin-bottom: 20px;
    }
    .form-control {
        height: 42px;
        border-radius: 3px;
        border-color: #DDDDDD;
        box-shadow: none;
        &:focus {
            box-shadow: 0 1px 3px rgba(#000, .08)
        }
    }
    .btn-default {
        font-size: 16px;
        padding-top: 9px;
        padding-bottom: 9px;
        padding-left: 20px;
        padding-right: 20px;
        border-color: $mainColor;
        color: $mainColor;
        outline: 0;
        &:hover {
            background-color: #FFF3F2;
        }
    }
}
.renting-item {
    background-color: #fff;
    border-radius: 3px;
    margin-bottom: 20px;
    @include clearfix;
    box-shadow: 0 1px 3px rgba(#000, .05);
    transition: .3s;
    display: table;
    width: 100%;
    min-height: 200px;
    &:hover {
        box-shadow: 0 2px 10px rgba(#000, .05);
    }
    .item-img {
        display: table-cell;
        width: 25%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-top-left-radius: 3px;
        border-bottom-right-radius: 3px;
    }
    .item-intro {
        display: table-cell;
        width: 60%;
        padding-left: 20px;
        padding-top: 20px;
        padding-right: 5%;
        vertical-align: top;
        dl {
            font-size: 14px;
            margin-top: 0;
            margin-bottom: 10px;
            dt {
                color: #8B8B8B;
                float: left;
                font-weight: normal;
                line-height: 1.5;
            }
            dd {
                margin-left: 80px;
                line-height: 1.5;
            }
        }
    }
    .item-title {
        margin-top: 0;
        margin-bottom: 10px;
        font-size: 18px;
        line-height: 1.5;
        vertical-align: top;
        .item-tags {
            display: inline-block;
            *display: inline;
            *zoom: 1;
            vertical-align: top;
            margin-left: 10px;
            a {
                display: inline-block;
                *display: inline;
                *zoom: 1;
                height: 20px;
                line-height: 20px;
                margin-right: 6px;
                font-size: 12px;
                color: #484848;
                background: #F8F8F8;
                border: 1px solid #DFE1E4;
                border-radius: 100px;
                padding: 0 10px;
            }
        }
    }
    .item-operate {
        display: table-cell;
        width: 25%;
        text-align: center;
        vertical-align: middle;
        height: 100%;
        border-left: 2px dashed #f1f1f1;
        position: relative;
        &:after {
            position: absolute;
            content: '';
            width: 18px;
            height: 18px;
            background-color: #F8F8F8;
            border-radius: 50%;
            left: -9px;
            top: -9px;
        }
        &:before {
            position: absolute;
            content: '';
            width: 18px;
            height: 18px;
            background-color: #F8F8F8;
            border-radius: 50%;
            left: -9px;
            bottom: -9px;
        }
        .operate-inner {
            vertical-align: middle;
        }
        .item-pirce {
            color: #484848;
            font-weight: bold;
            font-size: 18px;
            margin-bottom: 10px;
        }
        .item-status {
            font-size: 16px;
            margin-bottom: 20px;
            &.status-done {
                color: $mainColor;
            }
            &.status-pay {
                color: #15B800;
            }
            &.status-fail {
                color: #484848;
            }
        }
        .btn {
            margin-bottom: 10px;
        }
        .btn-default {
            font-size: 16px;
            padding-top: 9px;
            padding-bottom: 9px;
            padding-left: 30px;
            padding-right: 30px;
            border-color: $mainColor;
            color: $mainColor;
            outline: 0;
            background-color: #FFF3F2;
            border-radius: 3px;
            &:hover {
                opacity: .9;
            }
        }
        .btn-sub {
            font-size: 16px;
            padding-top: 9px;
            padding-bottom: 9px;
            padding-left: 30px;
            padding-right: 30px;
            border-color: #ccc;
            color: #666;
            outline: 0;
            background-color: #fafafa;
            border-radius: 3px;
            &:hover {
                opacity: .9;
            }
        }
    }
}
.lg-outer, .lg-backdrop {
    z-index: 99999999999;
}
.room-intro {
    background-color: #fff;
    padding-top: 15px;
    .intro-header {
        margin-bottom: 10px;
        .room-title {
            color: #4c4c4c;
            font-weight: bold;
            font-size: 21px;
            margin-top: 10px;
            margin-bottom: 10px;
            small {
                font-size: 16px;
                color: #666666;
                margin-left: 10px;
            }
            .item-tags {
                display: inline-block;
                *display: inline;
                *zoom: 1;
                vertical-align: bottom;
                margin-left: 30px;
                a {
                    display: inline-block;
                    height: 20px;
                    line-height: 20px;
                    margin-right: 10px;
                    font-size: 12px;
                    color: $mainColor;
                    border: 1px solid $mainColor;
                    border-radius: 100px;
                    padding: 0 10px;
                }
            }
        }
        .room-local {
            color: #8B8B8B;
            font-size: 14px;
            a {
                color: #484848;
                margin-left: 10px;
                &:hover {
                    color: $mainColor;
                }
            }
        }
    }
    .room-pics {
        height: 400px;
        line-height: 400px;
        overflow: hidden;
        .lslide {
            text-align: center;
            background-color: #F8F8F8;
            img {
                width: auto;
                height: 100%;
            }
        }
        .room-img {
            width: 100%;
            height: 400px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
    }
    .lSAction>a {
        opacity: 1;
    }
    .lSSlideOuter.vertical .lightSlider>* {
        overflow-y: hidden;
    }
    .lSSlideOuter .lSPager.lSGallery li {
        background-color: #F8F8F8;
        text-align: center;
    }
    .lSSlideOuter .lSPager.lSGallery img {
        width: auto;
        display: inline;
        height: 100%;
        width: 100%;
        object-fit: cover;
        vertical-align: top;
    }
    .lightSlider {
        margin-right: 5px;
        overflow: hidden;
    }
    .lSSlideOuter .lSPager.lSGallery li.active, .lSSlideOuter .lSPager.lSGallery li:hover {
        border-radius: 0;
    }
    .lSSlideOuter .lSPager.lSGallery li.active {
        position: relative;
        &:after {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            border: 2px solid $mainColor;
            z-index: 9;
        }
    }
}
.room-device {
    margin-top: 40px;
    i {
        display: inline-block;
        *display: inline;
        *zoom: 1;
        width: 40px;
        height: 40px;
        vertical-align: top;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        margin-right: 20px;
        &.device01 {
            background-image: url(../images/device01.png);
        }
        &.device02 {
            background-image: url(../images/device02.png);
        }
        &.device03 {
            background-image: url(../images/device03.png);
        }
        &.device04 {
            background-image: url(../images/device04.png);
        }
    }
    .device-intro {
        display: inline-block;
        *display: inline;
        *zoom: 1;
        vertical-align: top;
        font-size: 12px;
        color: #8B8B8B;
        p {
            color: #484848;
            font-size: 16px;
            margin-top: 2px;
        }
    }
}
.room-content {
    .content-menu {
        background-color: #fff;
        border-top: 1px solid #EEEEEE;
        border-bottom: 1px solid #EEEEEE;
        li {
            display: inline-block;
            *display: inline;
            *zoom: 1;
            font-size: 16px;
            vertical-align: top;
            padding: 12px 0;
            margin-right: 50px;
            cursor: pointer;
            position: relative;
            &.active {
                border-bottom: 2px solid $mainColor;
            }
        }
    }
    .content-inner {
        p {
            font-size: 15px;
        }
        padding-bottom: 50px;
        .inner-warp {
            width: 70%;
        }
    }
    .c-title {
        font-weight: bold;
        font-size: 16px;
        color: #484848;
        margin-top: 30px;
        margin-bottom: 10px;
    }
    .c-text {
        font-size: 14px;
        color: #4C4C4C;
        line-height: 25px;
    }
    .booking-box {
        background: #FFFFFF;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.10);
        .hd {
            font-size: 16px;
            color: #484848;
            padding: 14px 18px;
            border-bottom: 1px solid #eee;
            img {
                height: 25px;
                margin-right: 10px;
            }
        }
    }
    .booking-item {
        .booking-icon {
            width: 18px;
            margin-right: 10px;
        }
        padding: 18px 20px;
        @include clearfix;
        &:nth-child(even) {
            background-color: #F8F8F8;
        }
        .item-img {
            width: 120px;
            height: 80px;
            float: left;
            background-size: cover;
        }
        .item-intro {
            float: left;
            margin-left: 40px;
            width: 60%;
            h4 {
                font-size: 16px;
                color: #484848;
                margin-top: 0;
                margin-bottom: 20px;
                font-weight: normal;
            }
            dl {
                margin-top: 5px;
                margin-bottom: 0;
            }
            dt {
                display: inline-block;
                *display: inline;
                *zoom: 1;
                vertical-align: top;
                color: #8B8B8B;
                font-weight: normal;
            }
            dd {
                display: inline-block;
                *display: inline;
                *zoom: 1;
                color: #484848;
            }
        }
        .item-operate {
            float: right;
            text-align: right;
            .item-price {
                font-size: 18px;
                color: #E73828;
                margin-bottom: 15px;
            }
            .btn {
                font-size: 16px;
                background-color: transparent;
                padding-left: 30px;
                padding-right: 30px;
                border-color: #EC6053;
                color: #EC6053;
                outline: 0;
                &:hover {
                    background-color: #FFF3F2;
                }
            }
        }
    }
    .room-desc {
        @include clearfix;
        .desc-img {
            float: left;
            width: 30%;
            img {
                width: 100%;
            }
        }
        .desc-text {
            overflow-y: hidden;
            padding-left: 20px;
        }
    }
    .support-list {
        margin-top: 15px;
        color: #4C4C4C;
        .col-md-3 {
            margin-bottom: 20px;
        }
        i {
            display: inline-block;
            *display: inline;
            *zoom: 1;
            vertical-align: middle;
            width: 30px;
            height: 30px;
            background: url(../images/icon-garden.png) no-repeat center;
            background-size: 24px 24px;
            margin-right: 10px;
        }
    }
}
.room-detail-warp {
    position: relative;
    .intro-left {
        float: left;
        width: 70%;
    }
    .room-booking {
        float: right;
        width: 30%;
        padding-left: 15px;
        margin-bottom: 20px;
        .booking-inner {
            border: 1px solid #eee;
            background-color: #fff;
            border-radius: 3px;
        }
        .hd {
            text-align: center;
            height: 42px;
            line-height: 42px;
            color: #4C4C4C;
            border-bottom: 1px solid #eee;
            strong {
                font-size: 25px;
                font-weight: normal;
                display: inline-block;
                *display: inline;
                *zoom: 1;
                padding: 0 3px;
            }
        }
        .bd {
            padding: 20px;
        }
        .booking-info {
            text-align: center;
            color: #8B8B8B;
            margin-top: 20px;
            img {
                margin-bottom: 10px;
                width: 100px;
                height: 100px;
            }
            .info-cell {
                text-align: left;
                margin-top: 15px;
            }
        }
        .contact-user {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            float: left;
        }
        .contact-info {
            overflow: hidden;
            margin-left: 60px;
            position: relative;
            dl {
                margin-bottom: 10px;
            }
            dt {
                float: left;
                font-weight: normal;
            }
            dd {
                word-wrap: break-word;
                overflow: auto;
            }
            .tips {
                position: absolute;
                color: $mainColor;
                right: 15px;
                top: 50%;
                text-align: center;
                margin-top: -20px;
            }
        }
        .form-control {
            height: 40px;
            border-radius: 3px;
            border-color: #DDDDDD;
            box-shadow: none;
            padding-left: 45px;
            &:focus {
                box-shadow: 0 1px 3px rgba(#000, .08)
            }
        }
        .booking-in {
            background: url(../images/icon-in.png) no-repeat 10px center;
        }
        .booking-time {
            background: url(../images/icon-time.png) no-repeat 10px center;
        }
        select {
            padding-left: 45px;
            -webkit-appearance: none;
            -moz-appearance: none;
            -ms-appearance: none;
            -o-appearance: none;
            appearance: none;
            color: #9e9e9e;
        }
        .btn {
            background-color: $mainColor;
            color: #fff;
            font-size: 16px;
            padding-top: 8px;
            padding-bottom: 8px;
        }
        .booking-tools {
            a {
                color: #4c4c4c;
                font-size: 13px;
            }
        }
    }
}
.room-rim {
    padding: 50px 0;
    background-color: #fff;
    h3 {
        color: #4c4c4c;
        font-size: 30px;
        text-align: center;
        font-weight: normal;
        margin-bottom: 30px;
        margin-top: 0;
    }
}
.rim-slider, .search-histroy-slick {
    .slick-arrow {
        position: absolute;
        -webkit-appearance: none;
        border: 0;
        background-color: #fff;
        text-indent: -9999px;
        width: 30px;
        height: 30px;
        outline: 0;
        &.slick-prev {
            left: -35px;
            top: 50%;
            margin-top: -35px;
            background: url(../images/icon-left@2x.png) no-repeat center;
            background-size: 15px auto;
        }
        &.slick-next {
            right: -35px;
            top: 50%;
            margin-top: -35px;
            background: url(../images/icon-right@2x.png) no-repeat center;
            background-size: 15px auto;
        }
    }
    .histroy-item {
        outline: 0;
        padding: 0 10px;
        a:focus {
            outline: 0;
        }
        .item-img {
            height: 220px;
        }
    }
}
.search-histroy-slick {
    margin-top: 15px;
}
.search-histroy-slick .histroy-item {
    padding: 0;
}
.search-histroy-slick.slick-initialized .slick-slide {
    padding-left: 0;
    padding-right: 20px;
}
.login-warp {
    padding: 80px 0 120px;
}
.select-add {
    padding-top: 8px;
    display: inline-block;
    *display: inline;
    *zoom: 1;
    svg {
        path {
            fill: #666;
        }
    }
}
.login-box {
    margin: 0 auto;
    max-width: 600px;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 0 16px 0 rgba(76, 76, 76, .08);
    .hd {
        text-align: center;
        padding: 25px 0;
        border-bottom: 1px solid #eee;
        h2 {
            font-size: 24px;
            font-weight: bold;
            margin-top: 0;
            margin-bottom: 20px;
            color: #484848;
            position: relative;
            &:after {
                width: 30px;
                position: absolute;
                bottom: -15px;
                left: 50%;
                margin-left: -15px;
                height: 2px;
                background-color: #eee;
                content: '';
            }
        }
        p {
            font-size: 16px;
            margin: 0;
            color: #999;
        }
    }
    .bd {
        padding: 35px 15% 50px;
    }
    .form-group {
        margin-bottom: 20px;
        label {
            margin-bottom: 8px;
        }
        .text-danger {
            margin-top: 8px;
            color: darken($mainColor, 10);
            font-size: 13px;
        }
        sup {
            color: $mainColor;
        }
    }
    .code-btn {
        position: absolute;
        top: 27px;
        right: 0;
        height: 40px;
        line-height: 40px;
        text-align: center;
        width: 90px;
        background-color: $mainColor;
        color: #fff;
        border-radius: 3px;
        opacity: .8;
        &.disable {
            cursor: no-drop;
            border: 1px solid $mainColor;
            background-color: #fff;
            color: $mainColor;
        }
    }
    .code-group {
        padding-right: 100px;
        position: relative;
    }
    .code-group02 {
        padding-right: 100px;
        position: relative;
        .code-btn {
            top: 0;
        }
    }
    .fpw {
        float: right;
        color: #aaa;
        &:hover {
            color: $mainColor;
        }
    }
    .login-tip {
        margin: 30px 0;
        text-align: center;
        a {
            color: #aaa;
            &:hover {
                color: $mainColor;
            }
        }
    }
    .form-control {
        height: 40px;
        border-radius: 3px;
        border-color: #DDDDDD;
        box-shadow: none;
        &.erro {
            border-color: darken($mainColor, 10);
            box-shadow: 0 1px 3px rgba(darken($mainColor, 10), .2)
        }
        &:focus {
            box-shadow: 0 1px 3px rgba(#000, .08)
        }
    }
    textarea.form-control {
        height: auto;
    }
    .checkbox {
        margin: 0;
        color: #aaa;
        label {
            margin: 0;
        }
    }
    .btn-default {
        height: 40px;
        border-color: $mainColor;
        background-color: $mainColor;
        color: #fff;
        font-size: 16px;
        &:hover {
            background-color: darken($mainColor, 10)
        }
    }
    .third-login {
        text-align: center;
        margin: 50px 0 10px;
        .title {
            margin-bottom: 30px;
            font-size: 15px;
            position: relative;
            &:after, &:before {
                position: absolute;
                width: 30%;
                height: 1px;
                background-color: #eee;
                content: '';
                top: 50%;
            }
            &:before {
                left: 0;
            }
            &:after {
                right: 0;
            }
        }
        a {
            display: inline-block;
            *display: inline;
            *zoom: 1;
            margin: 0 15px;
        }
        img {
            height: 30px;
        }
    }
    .radio-item {
        input {
            display: none;
            &:checked+label {
                border: 1px solid $mainColor;
                color: $mainColor;
                small {
                    color: lighten($mainColor, 10);
                }
            }
        }
        label {
            border: 1px solid #DDDDDD;
            border-radius: 3px;
            padding: 20px;
            text-align: center;
            display: block;
            cursor: pointer;
            font-size: 16px;
            font-weight: normal;
            small {
                display: block;
                font-size: 13px;
                color: #999;
                margin-top: 8px;
            }
        }
    }
    .upload-img {
        @include clearfix();
        padding: 5px 12px;
        height: 40px;
        line-height: 28px;
        .a-upload {
            float: right;
        }
    }
    .a-upload {
        padding: 4px 10px;
        height: 28px;
        line-height: 20px;
        position: relative;
        cursor: pointer;
        color: #666;
        background: #F5F5F5;
        border-radius: 3px;
        overflow: hidden;
        display: inline-block;
        *display: inline;
        *zoom: 1;
        &:hover {
            color: #444;
            background: #eee;
            border-color: #ccc;
            text-decoration: none
        }
        input {
            position: absolute;
            font-size: 100px;
            right: 0;
            top: 0;
            opacity: 0;
            filter: alpha(opacity=0);
            cursor: pointer
        }
    }
}
.order-warp {
    margin: 30px 0 100px;
}
.order-box {
    padding: 30px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    box-shadow: 0 1px 3px rgba(#000, .03);
    border-radius: 3px;
    margin-bottom: 20px;
    position: relative;
}
.float-cs {
    width: 250px;
    position: absolute;
    top: 0;
    background-color: #fff;
    z-index: 999;
    right: 0;
    padding: 20px;
    border: 1px solid #eee;
    border-radius: 3px;
    text-align: center;
    box-shadow: 0 1px 3px rgba(#000, .1);
    h3 {
        margin-top: 0;
        font-size: 20px;
        margin-bottom: 10px;
        color: $mainColor;
    }
    .sub-title {
        margin-bottom: 15px;
    }
    .qrcode {
        width: 120px;
        margin-bottom: 15px;
    }
}
.order-content {
    max-width: 900px;
    margin: 0 auto;
    position: relative;
}
.order-title {
    text-align: center;
    margin-bottom: 50px;
    h1 {
        position: relative;
        margin-bottom: 15px;
        padding: 10px 0;
        font-size: 24px;
        text-align: center;
        &:after {
            position: absolute;
            bottom: -5px;
            left: 50%;
            content: "";
            width: 36px;
            height: 3px;
            margin-left: -18px;
            background: #F2F2F2;
        }
    }
    .sub-title {
        font-size: 16px;
        color: #666;
    }
}
.order-form {
    .control-label {
        text-align: left;
        font-size: 16px;
        font-weight: normal;
        padding-top: 9px;
        color: #666;
    }
    .form-group {
        margin: 15px 0;
        border-bottom: 1px dashed #f1f1f1;
        padding-bottom: 15px;
    }
    .form-cell:last-child {
        .form-group {
            border-bottom: 0;
        }
    }
    .order-text {
        line-height: 40px;
        font-size: 16px;
        color: #111;
    }
    .form-control {
        height: 40px;
        border-radius: 3px;
        border-color: #DDDDDD;
        box-shadow: none;
        &.erro {
            border-color: darken($mainColor, 10);
            box-shadow: 0 1px 3px rgba(darken($mainColor, 10), .2)
        }
        &:focus {
            box-shadow: 0 1px 5px rgba(#000, .08)
        }
    }
    textarea.form-control {
        height: auto;
    }
    .num-tip {
        display: inline-block;
        *display: inline;
        *zoom: 1;
        height: 40px;
        line-height: 40px;
        vertical-align: top;
        margin-left: 10px;
        cursor: pointer;
        svg {
            margin-top: 8px;
        }
    }
    .num {
        font-size: 0;
        white-space: nowrap;
        display: inline-block;
        *display: inline;
        *zoom: 1;
        vertical-align: top;
        input {
            border: none;
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
            width: 40px;
            height: 40px;
            color: #323232;
            outline: none;
            display: inline-block;
            *display: inline;
            *zoom: 1;
            vertical-align: top;
            font-size: 16px;
            text-align: center;
            border-radius: 0;
        }
        a {
            display: inline-block;
            *display: inline;
            *zoom: 1;
            width: 40px;
            height: 40px;
            line-height: 40px;
            font-size: 16px;
            vertical-align: top;
            text-align: center;
            cursor: pointer;
            color: #333;
            border: 1px solid #ddd;
        }
    }
    h3 {
        margin-top: 50px;
        font-size: 20px;
        padding-left: 10px;
        padding-bottom: 15px;
        border-bottom: 1px solid #eee;
    }
    .radio, .checkbox {
        margin-bottom: 15px;
        height: 40px;
        line-height: 40px;
        .form-control {
            display: inline-block;
            *display: inline;
            *zoom: 1;
        }
        input[type="radio"], input[type="checkbox"] {
            top: 17px;
        }
    }
    .radio-inline {
        margin-right: 10px;
    }
    .other-choice-input-container {
        display: inline-block;
        *display: inline;
        *zoom: 1;
        margin-left: 20px;
        .form-control {
            width: 280px;
        }
        .checkbox-inline {
            margin-right: 10px;
            padding-top: 0;
            input[type="radio"], input[type="checkbox"] {
                top: 10px;
            }
        }
    }
    .summary-text {
        font-size: 20px;
        height: 50px;
        line-height: 50px;
        b {
            color: $mainColor;
        }
    }
    .btn-next {
        height: 45px;
        width: 150px;
        font-size: 16px;
        line-height: 31px;
        border-color: $mainColor;
        color: $mainColor;
        background-color: transparent;
        outline: 0;
        margin-right: 20px;
        &:hover {
            background-color: #FFF3F2;
        }
    }
    .btn-end {
        height: 45px;
        width: 150px;
        font-size: 16px;
        line-height: 31px;
        border-color: $mainColor;
        color: #fff;
        background-color: $mainColor;
        outline: 0;
        &:hover {
            background-color: darken($mainColor, 5);
        }
    }
    .text-tip {
        line-height: 40px;
        margin: 0;
        color: darken($mainColor, 10);
    }
    .room-intro-img {
        img {
            width: 200px;
        }
    }
    span.price {
        color: $mainColor;
        font-weight: bold;
    }
    .add-room-cell {
        min-height: 350px;
    }
}
.add-room-box {
    margin: 20px 0;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #eee;
    padding: 20px;
    box-shadow: 0 1px 10px rgba(0, 0, 0, .08);
}
.text-gray {
    color: #888;
}
.text-green {
    color: #15B800;
}
.text-done {
    color: darken($mainColor, 10);
}
.uc-table {
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 10px rgba(#000, 0.05);
    text-align: center;
    .table>thead>tr>th {
        border-bottom: 2px solid #f1f1f1;
        padding: 15px 0;
        font-size: 13px;
        text-align: center;
    }
    .table>tbody>tr>td {
        border-top: 1px solid #eee;
        padding: 20px 10px;
        font-size: 13px;
        transition: .3s;
        a {
            color: #51A4DC;
            display: inline-block;
            *display: inline;
            *zoom: 1;
            margin: 0 3px;
            &:hover {
                color: $mainColor;
            }
        }
    }
    .table>tbody>tr:nth-of-type(odd) {
        background-color: #fafafa;
    }
    .table>tbody>tr:hover {
        td {
            // background-color: #f9f9f9;
        }
    }
    .room-img {
        width: 100px;
    }
}
.datepicker-input {
    background: url(../images/icon-time.png) no-repeat 95% center;
}
.uc-table-tools {
    margin-bottom: 20px;
    .form-control {
        height: 35px;
        border-radius: 3px;
        border-color: #DDDDDD;
        box-shadow: none;
        &.erro {
            border-color: darken($mainColor, 10);
            box-shadow: 0 1px 3px rgba(darken($mainColor, 10), .2)
        }
        &:focus {
            box-shadow: 0 1px 3px rgba(#000, .08)
        }
    }
    select {}
    .form-title {
        .sub-title {
            display: inline-block;
            *display: inline;
            *zoom: 1;
            vertical-align: top;
            height: 35px;
        }
    }
    .input-daterange {
        width: 80%;
    }
    .btn {
        font-size: 14px;
        padding-left: 20px;
        padding-right: 20px;
        border-color: $mainColor;
        color: $mainColor;
        background-color: transparent;
        outline: 0;
        &:hover {
            background-color: #FFF3F2;
        }
    }
    .form-search {
        margin: 0;
        input {
            width: 100%;
        }
    }
}
.custom-banner {
    height: 400px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba(#000, .1);
        left: 0;
        top: 0;
        z-index: 1;
    }
    h1 {
        text-align: center;
        position: absolute;
        z-index: 10;
        font-size: 42px;
        color: #fff;
        width: 100%;
        height: 100%;
        top: 40%;
        text-shadow: 0 0 1rem rgba(0, 0, 0, .2);
    }
}
.custom-intro {
    .intro-cell {
        padding: 60px 0;
        background-color: #fff;
        position: relative;
        &:nth-child(odd) {
            background-color: #f8f8f8;
        }
        &:after {
            position: absolute;
            content: '';
            left: 50%;
            top: 0;
            margin-left: -1px;
            width: 2px;
            background-color: #eee;
            height: 100%;
            z-index: 1;
        }
        &:before {
            position: absolute;
            content: '';
            left: 50%;
            top: 50%;
            width: 16px;
            height: 16px;
            margin-left: -8px;
            background-color: #eee;
            border-radius: 50%;
            z-index: 10;
            transition: .3s;
        }
        &:hover {
            &:before {
                background-color: $mainColor;
            }
        }
        &:first-child {
            &:after {
                top: 50%;
            }
        }
        &:last-child {
            &:after {
                height: 50%;
            }
            &:before {
                width: 20px;
                height: 20px;
                background-color: $mainColor;
                content: '✓';
                color: #fff;
                text-align: center;
            }
        }
    }
    .intro-img {
        float: left;
        width: 50%;
        padding: 0 5%;
        text-align: center;
        img {
            max-width: 350px;
            max-height: 300px;
        }
    }
    .intro-text {
        float: left;
        width: 50%;
        padding: 0 5%;
        h3 {
            font-size: 24px;
            margin-bottom: 20px;
            margin-top: 50px;
            line-height: 1.5;
        }
        p {
            font-size: 16px;
            color: #666;
            line-height: 1.5;
        }
        li {
            font-size: 14px;
            color: #666;
            line-height: 2;
            list-style-type: square;
        }
    }
}
.custom-end {
    text-align: center;
    background-color: #fff;
    padding: 100px 0;
    .btn {
        margin-top: 50px;
        font-size: 20px;
        padding: 12px 60px;
        border-color: $mainColor;
        color: $mainColor;
        background-color: transparent;
        outline: 0;
        &:hover {
            background-color: #FFF3F2;
        }
    }
}
.upload-cell {
    @include clearfix;
    margin-left: -15px;
    margin-right: -15px;
    font-size: 0;
    .upload-item {
        display: inline-block;
        *display: inline;
        *zoom: 1;
        vertical-align: top;
        font-size: 14px;
        width: 20%;
        padding: 15px;
        .item-img {
            img {
                width: 100%;
                max-height: 120px;
            }
            position: relative;
            overflow: hidden;
        }
        &:hover {
            .item-tool {
                bottom: 0;
            }
        }
        .item-tool {
            position: absolute;
            left: 0;
            right: 0;
            bottom: -28px;
            transition: .2s;
            background-color: #000;
            background-color: rgba(#000, .6);
            padding: 5px 10px;
            color: #fff;
            font-size: 12px;
            a {
                color: #fff;
                opacity: .8;
                float: left;
                &:hover {
                    opacity: 1;
                }
            }
            .set-del {
                float: right;
            }
        }
        .item-input {
            margin-top: 10px;
            input {
                width: 100%;
                border: 1px solid #eee;
            }
        }
    }
    .upload-btn {
        width: 100%;
        height: 120px;
        text-align: center;
        display: block;
        border-radius: 3px;
        border: 2px dashed #eee;
        padding-top: 30px;
        color: #666;
        transition: .3s;
        img {
            height: 35px;
            display: block;
            margin: 0 auto 10px;
        }
        &:hover {
            border-color: #ddd;
            color: #333;
        }
    }
}
.float-menu {
    overflow: visible;
    display: block;
    position: fixed;
    right: 0;
    bottom: 20%;
    z-index: 999999;
    height: 206px;
    margin-right: 20px;
    ul {
        width: 50px;
        text-align: center;
        li {
            position: relative;
            a {
                display: block;
                position: relative;
                width: 50px;
                height: 50px;
                margin-bottom: 2px;
                border: 1px solid $mainColor;
                background: #fff;
                color: $mainColor;
                &:hover {
                    b {
                        display: none;
                    }
                    span {
                        display: block;
                    }
                }
                &.weixin-link {
                    .weixin-pic {
                        display: none;
                        position: absolute;
                        right: 100%;
                        bottom: 0;
                        margin-right: 10px;
                        img {
                            width: 100px;
                            height: auto;
                        }
                    }
                    &:hover {
                        .weixin-pic {
                            display: block;
                        }
                    }
                }
            }
            b {
                display: block;
                width: 50px;
                height: 50px;
                padding: 5px;
                line-height: 20px;
                font-size: 13px;
                font-weight: normal;
                i {
                    display: block;
                    font-size: 20px;
                }
            }
            span {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 50px;
                height: 50px;
                padding: 6px 6px;
                line-height: 19px;
                font-size: 13px;
                display: none;
            }
        }
        .menu-detail {
            position: absolute;
            background: #fff;
            border: 1px solid $mainColor;
            padding: 5px;
            -webkit-transition: all .25s ease;
            transition: all .25s ease;
            opacity: 0;
            visibility: hidden;
            top: 0;
            right: 0;
            width: auto;
            -webkit-transition: all .25s ease;
            transition: all .25s ease;
            img {
                vertical-align: top;
                margin-bottom: 5px;
            }
            p {
                margin: 0;
                font-size: 12px;
            }
            &.tel {
                height: 50px;
                word-spacing: normal;
                width: 140px;
                text-align: left;
                line-height: 1;
                padding: 0;
                h6 {
                    font-size: 16px;
                    margin: 0;
                    color: #666;
                    font-style: italic;
                    font-weight: bold;
                    line-height: 50px;
                    vertical-align: top;
                    text-align: center;
                }
            }
        }
        li:hover {
            .menu-detail {
                opacity: 1;
                visibility: visible;
                right: 52px;
            }
        }
    }
}
.map-modal {
    overflow: hidden;
    #modal-map {
        height: 500px;
    }
    .modal-body {
        padding: 0;
        overflow: hidden;
    }
}
.reserve-modal {
    .login-box {
        box-shadow: none;
        padding: 20px;
    }
    .form-group {
        @include clearfix;
    }
    .login-box .form-group label {
        line-height: 40px;
        margin-bottom: 0;
    }
}
.about-inner {
    margin: 40px auto;
    max-width: 900px;
    text-align: center;
    h2 {
        color: #4c4c4c;
        font-size: 24px;
        line-height: 2em;
        font-weight: 400;
        margin: 0;
    }
    p {
        color: #63656a;
        font-size: 16px;
        line-height: 1.8em;
        display: block;
        width: 72%;
        margin: 0 auto;
        padding: 20px 0 0;
        a {
            color: inherit;
            border-bottom: 1px solid #ccc;
        }
    }
}
.intl-tel-input {
    display: block;
}
.iti-flag {
    background-image: url("../images/flags.png");
}
.iti-flag.bd {
    padding: 0;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .iti-flag {
        background-image: url("../images/flags@2x.png");
    }
}
@media (max-width: 768px) {
    .index-hero .hero-content h2 {
        font-size: 28px;
    }
    .index-intro .intro-list {
        li {
            min-height: 300px;
        }
    }
    .index-hero .hero-bg .slick-arrow {
        display: none!important;
    }
    .index-hero .hero-menu {
        width: 100%;
        left: 0;
        margin-left: 0;
        bottom: -140px;
        li {
            a {
                padding: 20px 0;
                p {
                    font-size: 14px;
                }
            }
            svg {
                width: 30px;
                margin-bottom: 10px;
            }
        }
    }
    .index-hot .hot-list li {
        width: 100%;
    }
    .header {
        position: relative;
    }
    .header .header-menu {
        display: none;
        position: absolute;
        left: 0;
        top: 59px;
        background: #fff;
        z-index: 9999999;
        width: 100%;
        border-bottom: 1px solid #eee;
        &.active {
            display: block;
        }
        li {
            float: none;
            border-top: 1px solid #eee;
            border-right: 0;
        }
    }
    .menu-toggle {
        float: right;
        width: 40px;
        height: 30px;
        margin-top: 15px;
        border: 1px solid #D7482E;
        border-radius: 3px;
        background: url(../images/icon-menu.png) no-repeat center;
        background-size: auto 15px;
    }
    .custom-intro .intro-img {
        padding-top: 20%;
        img {
            width: 100%;
        }
    }
    .float-cs {
        position: static;
        margin: 0 auto;
    }
    .order-box {
        padding: 30px 10px;
    }
    .order-form .other-choice-input-container {
        display: block;
        margin-left: 0;
    }
    .order-form .radio, .order-form .checkbox {
        height: auto;
    }
    .order-form .other-choice-input-container .checkbox-inline {
        display: block;
        margin-left: 0;
    }
    .order-form .other-choice-input-container .form-control {
        width: 250px;
    }
    .login-box .bd {
        padding-left: 15px;
        padding-right: 15px;
    }
    .room-intro {
        .intro-left {
            float: none;
            width: 100%;
        }
    }
    .room-intro .room-pics {
        width: 100%;
        float: none;
        height: 300px;
        .lSGallery {
            display: none;
        }
        .lSSlideWrapper {
            height: 300px!important;
        }
        .lSSlideOuter.vertical {
            padding-right: 0!important;
        }
        .lSSlideOuter.vertical .lSGallery {
            display: none;
        }
        .room-img {
            height: 280px;
        }
    }
    .room-intro .room-pics .lslide {
        height: 280px;
    }
    .room-intro .room-pics .lslide img {
        height: 100%;
        vertical-align: top;
    }
    .room-detail-warp .room-booking {
        width: 100%;
        float: none;
        position: static!important;
        margin: 0 auto 15px;
        padding-left: 0; // display: none;
    }
    .room-content .content-menu li {
        width: 20%;
        margin-right: 10px;
    }
    .room-content .content-inner .inner-warp {
        width: 100%;
    }
    .room-device {
        float: none;
        width: 100%;
        text-align: center;
        margin-bottom: 15px;
        margin-top: 0;
        i {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 10px;
        }
    }
    .room-content .booking-item {
        padding: 10px;
        .item-img {
            width: 100px;
        }
        .item-intro {
            margin-left: 20px;
        }
        .item-operate {
            float: left;
            margin-left: 120px;
            padding: 20px 0;
            text-align: left;
        }
    }
    .room-content .room-desc .desc-img {
        float: none;
        width: auto;
        margin-bottom: 10px;
    }
    .room-content .room-desc .desc-text {
        padding-left: 0;
    }
    .header-warp .sub-header {
        height: auto;
    }
    .search-filter {
        @include clearfix;
        width: 100%;
        float: none;
    }
    .search-tools {
        float: none;
        border-top: 1px solid #eee;
        margin-left: -15px;
        margin-right: -15px;
        .serach-form {
            width: 70%;
        }
        .search-toogle {
            border-right: 0;
        }
    }
    .header-warp .sub-header {
        // overflow: hidden;
    }
    .search-filter {
        @include clearfix; // overflow: hidden;
        font-size: 0;
        position: relative;
    }
    .search-filter .filter-item {
        width: 25%;
        margin: 0;
        font-size: 14px;
        position: static;
    }
    .sort-box .title {
        padding-left: 10px;
        padding-right: 10px;
    }
    .sort-box .sort-list li {
        padding-left: 10px;
        padding-right: 10px;
    }
    .search-map-warp {
        padding-top: 160px;
    }
    .upload-cell .upload-item {
        width: 50%;
    }
    .order-form .btn-next {
        margin-bottom: 10px;
        width: 120px;
    }
    .order-form .btn-end {
        width: 120px;
        margin-bottom: 10px;
        margin-left: 10px;
    }
    .school-select {
        input.form-control {
            margin-bottom: 10px;
        }
        .col-sm-3 {
            border-bottom: 1px dotted #eee;
            margin-bottom: 10px;
        }
    }
    .uc-table-tools .form-search {
        // float: left;
        // width: 70%;
        // margin-right: 10px;
        input {
            width: 100%;
        }
    }
    .uc-table-tools {
        .col-md-8 {
            text-align: left;
        }
        .col-xs-12 {
            margin-bottom: 10px;
        }
    }
    .header-warp .sub-header .sub-menu {
        @include clearfix;
    }
    .header-warp .sub-header .sub-menu li {
        float: left;
        width: 25%;
        a {
            text-align: center;
            padding: 0;
        }
    }
    .uc-table-tools {
        .input-daterange {
            width: 100%;
        }
    }
    .uc-table-tools .form-search {
        &.col-md-5 {
            width: 100%;
            .form-group {
                float: left;
                width: 100%; // width: 72%;
                margin-right: 10px;
            }
        }
    }
    .renting-item .item-img {
        width: 100%;
        float: none;
    }
    .renting-item .item-intro {
        width: 100%;
        float: none;
        padding-bottom: 20px;
    }
    .renting-item .item-operate {
        width: 100%;
        float: none;
        border-left: 0;
        border-top: 2px dashed #f1f1f1;
    }
    .renting-item .item-operate:before {
        right: -9px;
        left: auto;
        top: -9px;
    }
    .renting-item .item-title .item-tags {
        margin-left: 0;
        margin-top: 10px;
    }
    .float-menu {
        bottom: 10px;
        top: auto;
        margin-top: 0;
        margin-right: 10px;
    }
    .login-warp {
        padding: 0;
    }
    .search-histroy-slick.slick-initialized .slick-slide {
        padding-left: 10px;
        padding-right: 10px;
    }
    .search-histroy {
        overflow: hidden;
    }
    .search-histroy-slick, .rim-slider {
        padding-left: 20px;
        padding-right: 20px;
    }
    .rim-slider .slick-arrow.slick-prev, .search-histroy-slick .slick-arrow.slick-prev {
        left: -5px;
    }
    .rim-slider .slick-arrow.slick-next, .search-histroy-slick .slick-arrow.slick-next {
        right: -5px;
    }
    .room-intro .intro-header .room-title .item-tags {
        margin-left: 0;
        display: block;
    }
    .custom-intro .intro-img, .custom-intro .intro-text {
        padding-left: 4%;
        padding-right: 4%;
    }
    .order-warp {
        margin: 0;
        .container {
            padding: 0;
        }
        .order-box {
            margin-bottom: 0;
        }
    }
    .uc-warp {
        padding-top: 20px;
        padding-bottom: 0;
        .container {
            padding: 0;
        }
        .uc-box {
            padding: 15px;
        }
        .uc-table {
            border-radius: 0;
        }
    }
    .uc-warp h2.cell-title, .uc-table-tools {
        padding-left: 10px;
        padding-right: 10px;
    }
    .uc-form h3 {
        margin-top: 0;
    }
    .renting-list {
        overflow: hidden;
    }
    .uc-table-tools .btn {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
}
//serach-form respone width
@media (min-width: 768px) {
    .search-tools .serach-form {
        width: 237px;
    }
    .search-filter .filter-item {
        margin: 0 5px;
    }
}
@media (min-width: 992px) {
    .search-tools .serach-form {
        width: 403px;
    }
}
@media (min-width: 1200px) {
    .search-tools .serach-form {
        width: 403px;
    }
    .search-filter .filter-item {
        margin: 0 10px;
    }
}
@media (max-width: 480px) {
    .float-menu {
        display: none;
    }
}
.modal-backdrop {
    background: hsla(60, 12%, 97%, .95);
    opacity: 1;
}
.modal-backdrop.in {
    opacity: 1;
}
.modal-content {
    border: 0;
    box-shadow: 0 0 16px 0 rgba(76, 76, 76, .08);
}
.fq-warp {
    padding-top: 20px;
    padding-bottom: 80px;
    .fq-side {
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.03);
        border-radius: 3px;
        margin-bottom: 20px;
        position: relative;
        padding-bottom: 5px;
        h3 {
            margin-top: 0;
            margin-bottom: 0;
            font-size: 18px;
            line-height: 45px;
            padding: 0 20px;
            border-bottom: 1px solid #eee;
        }
        ul li {
            a {
                margin: 5px 0;
                display: block;
                line-height: 40px;
                font-size: 16px;
                padding-left: 20px;
                color: inherit;
                &:hover {}
            }
            &.active {
                a {
                    border-left: 3px solid $mainColor;
                    background-color: #f9f9f9;
                }
            }
        }
    }
    .fq-box {
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.03);
        border-radius: 3px;
        margin-bottom: 20px;
        position: relative;
        .fq-title {
            margin-top: 0;
            margin-bottom: 0;
            font-size: 20px;
            font-weight: normal;
            line-height: 45px;
            padding: 0 20px;
            border-bottom: 1px solid #eee;
        }
        .fq-content {
            padding: 20px 30px;
            h4 {
                font-size: 16px;
                font-weight: normal;
                color: $mainColor;
                margin-bottom: 10px;
            }
            p {
                font-size: 14px;
                color: #666;
                line-height: 2;
            }
        }
    }
}
.terms-warp {
    padding: 20px;
    padding-bottom: 80px;
}
.terms-box {
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.03);
    border-radius: 3px;
    position: relative;
    .terms-content {
        max-width: 980px;
        margin: 0 auto;
        padding: 30px 15px;
    }
    h1 {
        font-size: 30px;
        font-weight: normal;
        text-align: center;
        margin-top: 15px;
        margin-bottom: 30px;
    }
    h2 {
        font-size: 16px;
        margin-bottom: 10px;
        margin-top: 15px;
    }
    p {
        font-size: 14px;
        color: #666;
        line-height: 1.6;
        margin-bottom: 15px;
    }
}